import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { useMask } from '@react-input/mask'
import {
  json,
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
} from '@remix-run/node'
import {
  Form,
  useActionData,
  useSearchParams,
  type MetaFunction,
} from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import { AuthenticityTokenInput } from 'remix-utils/csrf/react'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import { z } from 'zod'

import { fallbackLng } from '@biogroup/i18n/config'
import { Button } from '@biogroup/ui/button'
import { ErrorList } from '@biogroup/ui/error-list'
import { Field } from '@biogroup/ui/field'

import { fallbackErrorLabels } from '#app/components/errors.tsx'
import { Layout } from '#app/components/layout.tsx'
import { RootLoaderType } from '#app/root.tsx'
import { requireAnonymous } from '#app/utils/auth.server.ts'
import { validateCSRF } from '#app/utils/csrf.server.ts'
import { isFeatureEnabled } from '#app/utils/features.ts'
import { honeypot } from '#app/utils/honeypot.server.ts'
import { i18nServer } from '#app/utils/i18n.server.ts'
import { useDelayedIsPending } from '#app/utils/misc.ts'
import {
  PatientLoginError,
  sendPatientLoginMail,
} from '#app/utils/patient.server.ts'

import { SentConfirmation } from './__sent-confirmation.tsx'

const LoginFormSchema = z.object({
  email: z.string().email(),
  dateOfBirth: z.string().regex(/^\d{2}\/\d{2}\/\d{4}$/),
  redirectTo: z.string().optional(),
})

export async function loader({ request }: LoaderFunctionArgs) {
  if (!isFeatureEnabled('FEATURE_PATIENT_ACCOUNTS', request.url)) {
    throw new Response('Not Found', { status: 404 })
  }

  await requireAnonymous(request)

  const t = await i18nServer.getFixedT(request)
  const { searchParams } = new URL(request.url)

  return json({
    redirectTo: searchParams.get('redirectTo') ?? undefined,
    meta: {
      title: t('page.login.meta.title'),
    },
  })
}

export const meta: MetaFunction<typeof loader, { root: RootLoaderType }> = ({
  data,
  matches,
}) => {
  const rootData = matches.find(m => m.id === 'root')?.data

  if (data?.meta) {
    return [{ title: data.meta.title }]
  } else {
    return [
      {
        title: fallbackErrorLabels.notFound?.[rootData?.locale ?? fallbackLng],
      },
    ]
  }
}

export async function action({ request }: ActionFunctionArgs) {
  const t = await i18nServer.getFixedT(request)
  const formData = await request.formData()

  await validateCSRF(formData, request.headers)
  honeypot.check(formData)

  const submission = await parseWithZod(formData, {
    async: true,
    schema: intent => {
      return LoginFormSchema.transform(async (data, ctx) => {
        if (intent !== null) return { ...data, session: null }

        const [error, result] = await sendPatientLoginMail({
          email: data.email,
          dateOfBirth: data.dateOfBirth,
          redirectTo: data.redirectTo,
        })

        const errorMessages: Record<string, string> = {
          [PatientLoginError.NotFound]: t('page.login.error.notFound'),
          [PatientLoginError.Unknown]: t('error.unknown'),
        }

        if (error) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: errorMessages[error],
          })
          return z.NEVER
        }

        if (!result?.success) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('error.unknown'),
          })
          return z.NEVER
        }

        return { data }
      })
    },
  })

  return json(
    { result: submission.reply() },
    { status: submission.status === 'error' ? 400 : 200 },
  )
}

export default function GuestLoginRoute() {
  const { t } = useTranslation()
  const actionData = useActionData<typeof action>()
  const isPending = useDelayedIsPending()
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo')

  const [form, fields] = useForm({
    id: 'login',
    constraint: getZodConstraint(LoginFormSchema),
    defaultValue: { redirectTo },
    lastResult: actionData?.result,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: LoginFormSchema })
    },
    shouldRevalidate: 'onBlur',
  })

  const maskRef = useMask({
    mask: '__/__/____',
    replacement: { _: /\d/ },
  })

  return (
    <Layout
      showIconLogo
      title={t('page.login.title')}
      subtitle={t('page.login.subtitle')}
    >
      {actionData?.result.status === 'success' ? (
        <SentConfirmation success />
      ) : (
        <Form
          method="POST"
          className="flex flex-col gap-y-4"
          {...getFormProps(form)}
        >
          <HoneypotInputs />
          <AuthenticityTokenInput />
          <Field
            labelProps={{ children: t('form.email.label') }}
            inputProps={{
              ...getInputProps(fields.email, { type: 'email' }),
              autoComplete: 'email',
            }}
            errors={fields.email.errors}
          />
          <Field
            ref={maskRef}
            labelProps={{ children: t('form.dateOfBirth.label') }}
            inputProps={{
              ...getInputProps(fields.dateOfBirth, {
                type: 'text',
              }),
              withReset: true,
              iconName: 'calendar',
              placeholder: t('form.dateOfBirth.placeholder'),
              autoComplete: 'bday',
              a11yLabels: {
                clear: t('a11y.clearInput'),
              },
            }}
            errors={fields.dateOfBirth.errors}
          />
          <ErrorList errors={form.errors} />
          <Button
            type="submit"
            className="mt-4 w-full"
            isLoading={isPending}
            disabled={isPending}
          >
            {t('cta.login')}
          </Button>
        </Form>
      )}
    </Layout>
  )
}
